(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name entitats.controller:EntitatsCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.entitats')
  .controller('EntitatsCtrl', EntitatsCtrl);

  function EntitatsCtrl(AclService, entitats, towns) {
    var vm = this;
    vm.ctrlName = 'EntitatsCtrl';
    vm.llistaEntitats = entitats;
    vm.can = AclService.can;

    angular.forEach(vm.llistaEntitats, function (entitat) {
      angular.forEach(towns, function (town) {
        if (town.id === entitat.town) {
          entitat.townName = town.name;
        }
      });
    });

    vm.query = {
      order: 'name'
    };

    vm.selected = [];
    vm.select = undefined;
  }
}());
